import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateJobArgs, SortDirection } from '../../generated/types';
import { GET_JOBS } from '../queries/getJobs';

export const CREATE_JOB = gql`
  mutation CreateJob($userUuid: String!, $boardUuid: String!) {
    createJob(userUuid: $userUuid, boardUuid: $boardUuid) {
      uuid
    }
  }
`;

export const useCreateJob = (options?: MutationHookOptions<Pick<Mutation, 'createJob'>, MutationCreateJobArgs>) =>
  useMutation<Pick<Mutation, 'createJob'>, MutationCreateJobArgs>(CREATE_JOB, {
    ...options,
  });
