import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUnfollowBoardArgs } from '../../generated/types';

export const UNFOLLOW_BOARD = gql`
  mutation UnfollowBoard($userUuid: String!, $boardUuid: String!, $followingUserUuid: String!) {
    unfollowBoard(userUuid: $userUuid, boardUuid: $boardUuid, followingUserUuid: $followingUserUuid)
  }
`;

export const useUnfollowBoard = (
  options?: MutationHookOptions<Pick<Mutation, 'unfollowBoard'>, MutationUnfollowBoardArgs>
) =>
  useMutation<Pick<Mutation, 'unfollowBoard'>, MutationUnfollowBoardArgs>(UNFOLLOW_BOARD, {
    awaitRefetchQueries: false,
    // onCompleted: (data) => {
    //   console.log('onCompleted', data);
    // },
    ...options,
  });
