import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateJobFromUrlArgs } from '../../generated/types';

export const CREATE_JOB_FROM_URL = gql`
  mutation CreateJobFromUrl($userUuid: String!, $boardUuid: String!, $data: JobFromUrlInputData!) {
    createJobFromUrl(userUuid: $userUuid, boardUuid: $boardUuid, data: $data)
  }
`;

export const useCreateJobFromUrl = (
  options?: MutationHookOptions<Pick<Mutation, 'createJobFromUrl'>, MutationCreateJobFromUrlArgs>
) =>
  useMutation<Pick<Mutation, 'createJobFromUrl'>, MutationCreateJobFromUrlArgs>(CREATE_JOB_FROM_URL, {
    ...options,
  });
