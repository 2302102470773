import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateResumeArgs } from '../../generated/types';

export const CREATE_RESUME = gql`
  mutation CreateResume($userUuid: String!, $boardUuid: String!) {
    createResume(userUuid: $userUuid, boardUuid: $boardUuid) {
      uuid
    }
  }
`;

export const useCreateResume = (
  options?: MutationHookOptions<Pick<Mutation, 'createResume'>, MutationCreateResumeArgs>
) =>
  useMutation<Pick<Mutation, 'createResume'>, MutationCreateResumeArgs>(CREATE_RESUME, {
    ...options,
  });
