import React, { Suspense, useRef, useState, useEffect } from 'react';
import { lazy } from 'react';
import { Link } from 'react-router-dom';
import { toast, Id } from 'react-toastify';
import { DateClickArg } from '@fullcalendar/interaction';

import { Urls } from '../../urls';
import { EmailTemplate, EntityType, Feeling, JobStatus, SortDirection, User } from '../../../generated/types';
import { Colors } from '../../../styles/colors';
import { formatUrl } from '../../../utils/url';
import generateID from '../../../helper/id';

import { useActiveTab } from '../../../hooks/useActiveTab';
import { useRouter } from '../../../hooks/useRouter';
import { usePushContext } from '../../../contexts/PushContext';

import { GET_BOARD, useGetBoard } from '../../../graph/queries/getBoard';
import { GET_JOBS, useGetJobs } from '../../../graph/queries/getJobs';
import { GET_JOB } from '../../../graph/queries/getJob';
import { usePublishBoard } from '../../../graph/mutations/publishBoard';
import { useCreateEvent } from '../../../graph/mutations/createEvent';
import { useArchiveJob } from '../../../graph/mutations/archiveJob';
import { useCreateJob } from '../../../graph/mutations/createJob';
import { useUpdateJob } from '../../../graph/mutations/updateJob';
import { useUpdateJobStatus } from '../../../graph/mutations/updateJobStatus';
import { useUnfollowBoard } from '../../../graph/mutations/unfollowBoard';
import { useInviteUserOnJob } from '../../../graph/mutations/inviteUserOnJob';
import { useInviteUserOnBoard } from '../../../graph/mutations/inviteUserOnBoard';
import { useCreateContact } from '../../../graph/mutations/createContact';
import { useCreateJobFromUrl } from '../../../graph/mutations/createJobFromUrl';
import { useCreateJobFromContent } from '../../../graph/mutations/createJobFromContent';
import { useCreateResume } from '../../../graph/mutations/createResume';
import { useArchiveResume } from '../../../graph/mutations/archiveResume';
import { useCopyResume } from '../../../graph/mutations/copyResume';
import { useRenderResume } from '../../../graph/mutations/renderResume';

import { ButtonsWrapper, ViewWrapper } from './View.styles';
import { SkeletonBlock } from '../../../components/atoms/Skeleton/SkeletonBlock';
import { Modal } from '../../../components/atoms/Modal/Modal';
import { PrimaryButton } from '../../../components/atoms/Button/Buttons';
import { PaperCollapse } from '../../../components/molecules/PaperCollapse';
import { Skeleton } from '../../../components/atoms/Skeleton';
import { TabController, TabPanel } from '../../../components/atoms/Tabs';
import { AddIcon, EditIcon, MailIcon, UploadIcon } from '../../../components/atoms/Icons';
import { SmallLoader } from '../../../components/atoms/Loader/Loader';
import { ErrorBoundary } from '../../../components/molecules/ErrorBoundary';
import { BoardDescription } from '../../../components/organisms/BoardDescription';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { useUserContext } from '../../../contexts/UserContext';
import { ProcessedFile } from '../../../components/molecules/ProcessedFile/ProcessedFile';
import { Copy } from '../../../components/atoms/Typography';
import { ProcessedResume, ProcessedResumeCreate } from '../../../components/molecules/ProcessedResume/ProcessedResume';
import { ProcessedFiles } from '../../../components/molecules/ProcessedFile/ProcessedFile.styles';
import { useCreateResumeFromFile } from '../../../graph/mutations/createResumeFromFile';
import { Toast } from '../../../components/atoms/Toast';

const ImportJobModal = lazy(() => import('../../../components/organisms/ImportJobModal'));
const AddContactModal = lazy(() => import('../../../components/organisms/AddContactModal'));
const ConfirmDeleteModal = lazy(() => import('../../../components/organisms/ConfirmDeleteModal'));
const InviteUserOnJobModal = lazy(() => import('../../../components/organisms/InviteUserOnJobModal'));
const InviteUserOnBoardModal = lazy(() => import('../../../components/organisms/InviteUserOnBoardModal'));
const AddEventModal = lazy(() => import('../../../components/organisms/AddEventModal'));
const AddEventFromCalendarModal = lazy(() => import('../../../components/organisms/AddEventFromCalendarModal'));
const CloseJobModal = lazy(() => import('../../../components/organisms/CloseJobModal'));
const JobsViewTable = lazy(() => import('./JobsTable.styles'));
const JobsKanban = lazy(() => import('./JobsKanban.styles'));
const JobsCalendar = lazy(() => import('./JobsCalendar.styles'));
const JobsMap = lazy(() => import('./JobsMap.styles'));

export const BoardView: React.FC<{}> = () => {
  const toastId = useRef<Id | null>(null);
  // Used to reload the kanban view
  const [activeKey, setActiveKey] = useState<number>(1);
  const [activeTab, setActiveTab] = useActiveTab('list');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalVisibleType, setModalVisibleType] = useState<
    | ''
    | 'invite_job'
    | 'invite_board'
    | 'add_event'
    | 'add_event_from_calendar'
    | 'confirm_remove_follower'
    | 'add_contact'
    | 'close_job'
    | 'delete_job'
    | 'delete_resume'
    | 'import_job'
    | 'imported_job'
  >('');
  const [followingUserUuid, setFollowingUserUuid] = useState('');
  const [jobUuid, setJobUuid] = useState<string>('');
  const [resumeUuid, setResumeUuid] = useState<string>('');
  const [currentDate, setCurrentDate] = useState<string>('');
  const { query: { userUuid = '', boardUuid = '' } = {}, navigate } = useRouter();
  const {
    error: boardError,
    data: { board } = {},
    loading: boardLoading,
    refetch: boardRefetch,
  } = useGetBoard({
    variables: { userUuid, boardUuid },
    fetchPolicy: 'cache-first',
  });
  const {
    data: { jobs = [] } = {},
    loading: jobsLoading,
    refetch: jobsRefetch,
  } = useGetJobs({
    variables: {
      where: {
        boardUuid,
        userUuid,
        isDeleted: false,
      },
      sort: { direction: SortDirection.Desc, field: 'updatedAt' },
    },
    // fetchPolicy: 'cache-first', @todo fix to use cache-first.
    fetchPolicy: 'cache-and-network',
  });
  const [unfollowBoard] = useUnfollowBoard();
  const [archiveJob] = useArchiveJob();
  const [archiveResume] = useArchiveResume();
  const [publishJob] = usePublishBoard();
  const [createEvent] = useCreateEvent();
  const [createContact] = useCreateContact();
  const [createJob, _createJobState] = useCreateJob();
  const [createResume, _createResumeState] = useCreateResume();
  const [createResumeFromFile, _createResumeStateFromFile] = useCreateResumeFromFile();
  const [copyResume, _copyResumeState] = useCopyResume();
  const [updateJob] = useUpdateJob();
  const [updateJobStatus] = useUpdateJobStatus();
  const [inviteUserOnJob] = useInviteUserOnJob();
  const [inviteUserOnBoard] = useInviteUserOnBoard();
  const [createJobFromUrl] = useCreateJobFromUrl();
  const [createJobFromContent] = useCreateJobFromContent();
  const [renderResume] = useRenderResume();
  const user = useUserContext();
  const { refetchQueries, pushState, clearPushState, clearRefetchQueries } = usePushContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (refetchQueries?.board_view === 'QUERY_JOBS' || refetchQueries?.taskId) {
      jobsRefetch();
      toast.success('The Jobs Board Listing has been updated.');
      clearRefetchQueries();
    }
    if (refetchQueries?.board_view === 'QUERY_GET_BOARD' || refetchQueries?.taskId) {
      boardRefetch();
      toast.success('The Jobs Board has been updated.');
      clearRefetchQueries();
    }
  }, [refetchQueries?.board_view, refetchQueries?.taskId]);

  useEffect(() => {
    if (!pushState) return;
    const { type, jobUuid } = pushState;
    switch (type) {
      case 'processScrapeJobFromContentCompleted':
      case 'processScrapeJobFromUrlCompleted': {
        setJobUuid(jobUuid);
        clearPushState();
        break;
      }
      case 'processScrapeJobFromUrlFailed':
      case 'processScrapeJobFromContentFailed':
        setModalVisibleType('');
        setIsModalVisible(false);
        toast.error(pushState?.errorMessage || 'An error occurred while importing the job application.');
        break;
    }
  }, [pushState]);

  // Board Actions
  async function handleBoardPublishClick(isPublic: boolean) {
    toastId.current = toast.loading(isPublic ? 'Publishing...' : 'Unpublishing...');
    try {
      await publishJob({
        variables: {
          userUuid,
          boardUuid,
          isPublic,
        },
      });
      const message = isPublic
        ? 'The Jobs Board and uploaded documents (CVs, Resumes) are now public.'
        : 'The Jobs Board and uploaded documents (CVs, Resumes) are now private.';
      toastId.current && toast.dismiss(toastId.current);
      toast.success(message);
    } catch (error) {
      console.error(error);
      toastId.current && toast.dismiss(toastId.current);
      toast.error(
        `We apologize, but we were unable to ${
          isPublic ? 'publish' : 'unpublish'
        } your Job's board at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.`
      );
    }
  }

  async function handleBoardInviteClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setModalVisibleType('invite_board');
    setIsModalVisible(true);
  }

  async function handleBoardInvite(values: {
    email: string;
    subject: string;
    content: string;
    emailTemplate: EmailTemplate;
  }) {
    try {
      await inviteUserOnBoard({
        variables: {
          userUuid,
          boardUuid,
          data: {
            email: values.email,
            subject: values.subject,
            emailTemplate: EmailTemplate.InviteBoardAgent,
            params: {
              content: values.content,
              ctaUrl: `${import.meta.env.VITE_APP_URL}/${Urls.Boards}/${formatUrl(Urls.BoardView, {
                userUuid,
                boardUuid,
              })}`,
            },
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_BOARD,
            variables: { userUuid, boardUuid },
          },
        ],
      });
      toast.success('Agent/Recruiter invited successfully to collaborate on this Jobs Board.');
    } catch (error) {
      console.error(error);
      toast.error(
        'We apologize, but we were unable to send an invite at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleBoardRemoveFollowerClick(followingUser: User) {
    setFollowingUserUuid(followingUser.uuid);
    setModalVisibleType('confirm_remove_follower');
    setIsModalVisible(true);
  }

  async function handleFollowerDelete() {
    try {
      unfollowBoard({
        variables: {
          userUuid,
          boardUuid,
          followingUserUuid,
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_BOARD, variables: { userUuid, boardUuid } }],
      });
      setFollowingUserUuid('');
      toast.success('The collaboration with the agent/recruiter has been successfully disabled for this Jobs Board');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to cancel the collaboration on this Jobs Board. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleJobCreateClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    try {
      const result = await createJob({
        variables: {
          boardUuid,
          userUuid,
        },
      });

      if (result.data?.createJob?.uuid) {
        navigate(
          formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep1}`, {
            userUuid,
            boardUuid,
            jobUuid: result.data?.createJob?.uuid,
          }),
          {
            state: { from: `/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}` },
          }
        );
      }
    } catch (error: any) {
      let message =
        'We apologize, but we were unable to create your new Job Opportunity this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.';
      if (error.graphQLErrors[0].extensions.code === 'MAX_JOBS_REACHED') {
        message =
          'You have reached the maximum number of Jobs on this board allowed for your current plan. Archive some of your existing Jobs Opportunities to create a new one.';
      }
      toast.error(message);
    }
  }

  async function handleBoardEditClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    navigate(
      formatUrl(`/${Urls.Boards}/${Urls.BoardUpdate}`, {
        userUuid,
        boardUuid,
      }),
      {
        state: { from: `/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}` },
      }
    );
  }

  async function handleJobImportClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setModalVisibleType('import_job');
    setIsModalVisible(true);
  }

  async function handleJobImportedClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setModalVisibleType('');
    setIsModalVisible(true);
    navigate(`${Urls.JobUpdateHome}/${formatUrl(Urls.JobUpdateStep1, { userUuid, boardUuid, jobUuid: jobUuid })}`);
  }

  // Jobs Actions
  async function handleJobViewClick(jobUuid: string) {
    navigate(`/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`, {
      state: { from: `/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}` },
    });
  }

  async function handleJobEditClick(jobUuid: string) {
    navigate(
      formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep1}`, {
        userUuid,
        boardUuid,
        jobUuid,
      }),
      {
        state: { from: `/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}` },
      }
    );
  }

  async function handleJobDeleteClick(jobUuid: string) {
    setJobUuid(jobUuid);
    setModalVisibleType('delete_job');
    setIsModalVisible(true);
  }

  async function handleJobDeleteConfirm() {
    if (!jobUuid) {
      setModalVisibleType('');
      setIsModalVisible(false);
      toast.error(
        'We apologize, but we were unable to send an archive the Job Application at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
      return;
    }

    async function handleJobDeleteUndo(jobUuid: string, isDeleted: boolean = false) {
      await archiveJob({
        variables: {
          boardUuid,
          jobUuid,
          isDeleted: false,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_JOBS,
            variables: {
              where: {
                boardUuid,
                userUuid,
                isDeleted: false,
              },
              sort: { direction: SortDirection.Desc, field: 'createdAt' },
            },
          },
        ],
      });
    }

    try {
      await archiveJob({
        variables: {
          boardUuid,
          jobUuid,
          isDeleted: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_JOBS,
            variables: {
              where: {
                boardUuid,
                userUuid,
                isDeleted: false,
              },
              sort: { direction: SortDirection.Desc, field: 'createdAt' },
            },
          },
        ],
      });
      toast.success(
        <Toast onUndo={() => handleJobDeleteUndo(jobUuid)}>The Job Application has been successfully deleted.</Toast>,
        {
          autoClose: 6000,
        }
      );
    } catch (error) {
      console.error(error);
      toast.error(
        'We apologize, but we were unable to send an archive the Job Application at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
    } finally {
      setModalVisibleType('');
      setIsModalVisible(false);
    }
  }

  async function handleJobInviteClick(jobUuid: string) {
    setJobUuid(jobUuid);
    setModalVisibleType('invite_job');
    setIsModalVisible(true);
  }

  async function handleJobInvite(values: {
    email: string;
    subject: string;
    content: string;
    emailTemplate: EmailTemplate;
  }) {
    try {
      await inviteUserOnJob({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          data: {
            email: values.email,
            subject: values.subject,
            emailTemplate: EmailTemplate.InviteJobAgent,
            params: {
              content: values.content,
              ctaUrl: `${import.meta.env.VITE_APP_URL}/${Urls.Jobs}/${formatUrl(Urls.JobView, {
                userUuid,
                boardUuid,
                jobUuid,
              })}`,
            },
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_JOB,
            variables: { userUuid, boardUuid, jobUuid },
          },
        ],
      });
      toast.success('Agent/Recruiter invited successfully to collaborate on this Job Application');
    } catch (error) {
      console.error(error);
      toast.error(
        'We apologize, but we were unable to send an invite at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleJobAddEventClick(jobUuid: string) {
    setJobUuid(jobUuid);
    setModalVisibleType('add_event');
    setIsModalVisible(true);
  }

  async function handleJobAddContactClick(jobUuid: string) {
    setJobUuid(jobUuid);
    setModalVisibleType('add_contact');
    setIsModalVisible(true);
  }

  async function handleJobAddEventSubmit(values: any) {
    const { jobUuid: currentJobUuid, ...rest } = values;
    try {
      await createEvent({
        variables: {
          userUuid,
          boardUuid,
          jobUuid: currentJobUuid ? currentJobUuid : jobUuid,
          data: {
            ...rest,
            startAt: `${values.startAt}:00Z`,
            endAt: values.endAt ? `${values.endAt}:00Z` : null,
          },
        },
      });
      setIsModalVisible(false);
      setModalVisibleType('');
      toast.success('The Event has been successfully created.');
    } catch (error) {
      console.error(error);
    }
  }

  async function handleJobAddContactSubmit(values: any) {
    try {
      await createContact({
        variables: {
          entityType: boardUuid ? EntityType.Job : EntityType.Jobpost,
          userUuid,
          boardUuid,
          jobUuid,
          data: values,
        },
      });
      setIsModalVisible(false);
      setModalVisibleType('');
    } catch (error) {
      console.error(error);
    }
  }

  async function handleJobDrag(jobUuid: string, jobStatus: JobStatus) {
    if (jobStatus === JobStatus.Closed) {
      setModalVisibleType('close_job');
      setIsModalVisible(true);
      setJobUuid(jobUuid);
    } else {
      try {
        updateJobStatus({
          variables: {
            userUuid,
            boardUuid,
            jobUuid,
            status: jobStatus,
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function handleCloseJobSubmit() {
    try {
      updateJob({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          data: {
            status: JobStatus.Closed,
          },
        },
      });

      toast.success('The Job Application has now been closed.');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to update the job status on the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleEventDrag(info: any) {
    // console.log('handleEventDrag', info);
  }

  async function handleCalendarAddEvent(info: DateClickArg) {
    // console.log('handleCalendarAddEvent', info);
    setIsModalVisible(true);
    setModalVisibleType('add_event_from_calendar');
    setCurrentDate(info.dateStr);
  }

  async function handleJobUrlSubmit(url: string) {
    const taskId = generateID();
    toastId.current = toast.loading('The Job Application is been imported...', {
      toastId: taskId,
      autoClose: 20000,
    });
    try {
      await createJobFromUrl({
        variables: {
          userUuid,
          boardUuid,
          data: {
            url,
            taskId,
          },
        },
      });
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to upload the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = 'You are not authorized to import a Job Application on this Jobs Board.';
      }
      toastId.current && toast.dismiss(toastId.current);
      toast.error(displayMessage);
    }
  }

  async function handleJobContentSubmit(content: string) {
    const taskId = generateID();
    toastId.current = toast.loading('The Job Application is been imported...', {
      toastId: taskId,
      autoClose: 20000,
    });
    try {
      await createJobFromContent({
        variables: {
          userUuid,
          boardUuid,
          data: {
            content,
            taskId,
          },
        },
      });
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to upload the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = 'You are not authorized to import a Job Application on this Jobs Board.';
      }
      toastId.current && toast.dismiss(toastId.current);
      toast.error(displayMessage);
    }
  }

  function handleCancel(event: any) {
    event.preventDefault();
    setIsModalVisible(false);
  }

  function handleCancelJobClose(event: any) {
    event.preventDefault();
    setIsModalVisible(false);
    setActiveKey((prev) => prev + 1);
  }

  // Resume Actions
  async function handleResumeCreateFromScratch(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();

    try {
      const result = await createResume({
        variables: {
          boardUuid,
          userUuid,
        },
        awaitRefetchQueries: false,
        refetchQueries: [
          {
            query: GET_BOARD,
            variables: { userUuid, boardUuid },
          },
        ],
      });

      if (result.data?.createResume?.uuid) {
        navigate(
          formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
            userUuid,
            boardUuid,
            stepId: '1',
            resumeUuid: result.data?.createResume?.uuid,
          }),
          {
            state: { from: `/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}` },
          }
        );
      }
    } catch (error: any) {
      let message =
        'We apologize, but we were unable to create your new resume this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.';
      if (error.graphQLErrors[0].extensions.code === 'MAX_JOBS_REACHED') {
        message = 'You have reached the maximum number of resume on this board allowed for your current plan.';
      }
      toast.error(message);
    }
  }

  async function handleResumeCreateFromResume(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    const isMember = user?.isMember;
    if (isMember) {
      toast.success('Upload a resume and let our AI do the work for you.');
      navigate(`/${Urls.Boards}/${formatUrl(Urls.BoardUpdate, { userUuid, boardUuid })}`);
    } else {
      navigate('/checkout');
      return;
    }
    try {
    } catch (error: any) {}
  }

  async function handleResumeCreateFromFileClick(fileUuid: string) {
    try {
      const result = await createResumeFromFile({
        variables: {
          userUuid,
          boardUuid,
          fileUuid,
        },
        awaitRefetchQueries: false,
        refetchQueries: [
          {
            query: GET_BOARD,
            variables: { userUuid, boardUuid },
          },
        ],
      });

      if (result.data?.createResumeFromFile?.uuid) {
        navigate(
          formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
            userUuid,
            boardUuid,
            stepId: '1',
            resumeUuid: result.data?.createResumeFromFile?.uuid,
          }),
          {
            state: { from: `/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}` },
          }
        );
      }
    } catch (error: any) {
      let message =
        'We apologize, but we were unable to create your new resume this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.';
      if (error.graphQLErrors[0].extensions.code === 'MAX_JOBS_REACHED') {
        message = 'You have reached the maximum number of resume on this board allowed for your current plan.';
      }
      toast.error(message);
    }
  }

  async function handleResumeCopyClick(resumeUuid: string) {
    try {
      const result = await copyResume({
        variables: {
          boardUuid,
          userUuid,
          resumeUuid,
        },
        awaitRefetchQueries: false,
        refetchQueries: [
          {
            query: GET_BOARD,
            variables: { userUuid, boardUuid },
          },
        ],
      });

      if (result.data?.copyResume?.uuid) {
        navigate(
          formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
            userUuid,
            boardUuid,
            stepId: '1',
            resumeUuid: result.data?.copyResume?.uuid,
          }),
          {
            state: { from: `/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}` },
          }
        );
      }
    } catch (error: any) {
      let message =
        'We apologize, but we were unable to create your new resume this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.';
      if (error.graphQLErrors[0].extensions.code === 'MAX_JOBS_REACHED') {
        message = 'You have reached the maximum number of resume on this board allowed for your current plan.';
      }
      toast.error(message);
    }
  }

  async function handleResumeEditClick(resumeUuid: string) {
    navigate(
      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.BoardResumeUpdateStepGeneric}`, {
        userUuid,
        boardUuid,
        resumeUuid,
        stepId: '1',
      }),
      {
        state: { from: `/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}#resumes` },
      }
    );
  }

  async function handleResumeDeleteConfirm() {
    if (!resumeUuid) {
      setModalVisibleType('');
      setIsModalVisible(false);
      toast.error(
        'We apologize, but we were unable to archive the resume at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
      return;
    }
    try {
      await archiveResume({
        variables: {
          boardUuid,
          resumeUuid,
          isDeleted: true,
        },
      });
      toast.success('The resume has been successfully deleted.');
    } catch (error) {
      console.error(error);
      toast.error(
        'We apologize, but we were unable to archive the resume at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
    } finally {
      setModalVisibleType('');
      setIsModalVisible(false);
    }
  }

  async function handleResumeDeleteClick(resumeUuid: string) {
    setResumeUuid(resumeUuid);
    setModalVisibleType('delete_resume');
    setIsModalVisible(true);
  }

  async function handleResumeDownload(resumeUuid: string): Promise<void> {
    const { isMember } = user || {};
    if (!isMember) {
      navigate(`/${Urls.Checkout}`);
      return;
    }

    let taskId;
    try {
      taskId = toast.loading('🚀 The resume is been rendered. Please wait', {
        autoClose: 10000,
        position: 'top-left',
      });
      const result = await renderResume({
        variables: {
          userUuid,
          boardUuid,
          resumeUuid,
        },
      });
      if (result.data?.renderResume) {
        window.open(result.data?.renderResume);
      } else {
        throw new Error('Unable to render the resume.');
      }
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to render the resume. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      toast.error(displayMessage);
    } finally {
      toast.dismiss(taskId);
    }
  }

  async function handleJobFeelingSelect(jobUuid: string, feeling: Feeling) {
    try {
      await updateJob({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          data: {
            feeling,
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  let boardComponent = null;
  if (boardError) {
    navigate(`/${Urls.Boards}`);
  } else if (boardLoading) {
    boardComponent = <SkeletonBlock style={{ height: '75px', marginBottom: '25px' }} />;
  } else if (board) {
    const canAddJob = board.permissions?.includes('ADD_JOB') || false;
    const canEditBoard = board.permissions?.includes('EDIT_BOARD') || false;
    const canInvite = board.permissions?.includes('INVITE_BOARD') || false;
    const canImport = board.permissions?.includes('IMPORT_JOB') || false;

    boardComponent = (
      <ErrorBoundary message="Route: \Board\View Element: Board Component">
        {/* <pre>{JSON.stringify(board, null, 2)}</pre> */}
        <PaperCollapse
          title="Board Details / Permissions"
          open={false}
          action={
            <ButtonsWrapper>
              {canInvite && (
                <PrimaryButton
                  iconRight={<MailIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleBoardInviteClick}
                  size="small"
                >
                  Invite
                </PrimaryButton>
              )}

              {canEditBoard && (
                <PrimaryButton
                  iconRight={<EditIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleBoardEditClick}
                  size="small"
                >
                  Edit Board
                </PrimaryButton>
              )}

              {canImport && (
                <PrimaryButton
                  iconRight={<UploadIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleJobImportClick}
                  size="small"
                >
                  Import Job
                </PrimaryButton>
              )}

              {canAddJob && (
                <PrimaryButton
                  iconRight={<AddIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleJobCreateClick}
                  size="small"
                >
                  Add Job
                </PrimaryButton>
              )}
            </ButtonsWrapper>
          }
        >
          <BoardDescription
            onPublishClick={handleBoardPublishClick}
            onFollowerDeleteClick={handleBoardRemoveFollowerClick}
            user={user}
            handleJobUrlSubmit={handleJobUrlSubmit}
            handleJobContentSubmit={handleJobContentSubmit}
            {...board}
          />
        </PaperCollapse>
      </ErrorBoundary>
    );
  }

  let jobsComponent = null;
  if (jobsLoading) {
    jobsComponent = (
      <>
        <Skeleton>
          <rect x="0" y="0" rx="0" ry="0" width="75" height="20" />
          <rect x="80" y="0" rx="0" ry="0" width="75" height="20" />
          <rect x="160" y="0" rx="0" ry="0" width="75" height="20" />
          <rect x="0" y="30" rx="3" ry="3" width="600" height="40" />
          <rect x="0" y="80" rx="3" ry="3" width="600" height="40" />
          <rect x="0" y="130" rx="3" ry="3" width="600" height="40" />
        </Skeleton>
      </>
    );
  } else if (jobs && board) {
    const canUpdateKanban = board?.permissions?.includes('UPDATE_KANBAN') || false;
    const canSeeMap = board?.permissions?.includes('SEE_MAP') || false;
    const canSeeProcessedFiles = board?.permissions?.includes('SEE_PROCESSED_FILES') || false;
    const filteredJobs = jobs.filter((job) => job.isDeleted === false);

    jobsComponent = (
      <ErrorBoundary message="Board\View\JobsList">
        <TabController
          headers={[
            { id: 'list', label: 'List' },
            ...(canSeeProcessedFiles ? [{ id: 'resumes', label: 'Resumes' }] : []),
            ...(canUpdateKanban ? [{ id: 'kanban', label: 'Kanban' }] : []),
            { id: 'calendar', label: 'Calendar' },
            ...(canSeeMap ? [{ id: 'mapview', label: 'Map' }] : []),
            // { id: 'archive', label: 'Archive' },
          ]}
          initialActiveId={activeTab}
          onTabChanged={(tabId) => {
            setActiveTab(tabId);
          }}
        >
          <TabPanel $tabId="list">
            <ErrorBoundary>
              <JobsViewTable
                user={user}
                jobs={filteredJobs}
                handleViewClick={handleJobViewClick}
                handleEditClick={handleJobEditClick}
                handleDeleteClick={handleJobDeleteClick}
                handleAddEventClick={handleJobAddEventClick}
                handleInviteClick={handleJobInviteClick}
                handleAddContactClick={handleJobAddContactClick}
                handleFeelingSelect={handleJobFeelingSelect}
                // handleJobStatusSelect={handleJobStatusSelect}
              />
            </ErrorBoundary>
          </TabPanel>
          <TabPanel $tabId="kanban">
            <Suspense fallback={<SmallLoader />}>
              <ErrorBoundary>
                <JobsKanban
                  key={activeKey}
                  userUuid={userUuid}
                  board={board}
                  jobs={filteredJobs}
                  handleCardDrag={handleJobDrag}
                ></JobsKanban>
              </ErrorBoundary>
            </Suspense>
          </TabPanel>
          <TabPanel $tabId="calendar">
            <Suspense fallback={<SmallLoader />}>
              <ErrorBoundary>
                <JobsCalendar
                  board={board}
                  handleCardDrag={handleEventDrag}
                  handleDateSelect={handleCalendarAddEvent}
                ></JobsCalendar>
              </ErrorBoundary>
            </Suspense>
          </TabPanel>
          <TabPanel $tabId="mapview">
            <Suspense fallback={<SmallLoader />}>
              <ErrorBoundary>
                <JobsMap board={board} jobs={jobs}></JobsMap>
              </ErrorBoundary>
            </Suspense>
          </TabPanel>
          <TabPanel $tabId="resumes">
            <Suspense fallback={<SmallLoader />}>
              <ErrorBoundary>
                <ProcessedFiles>
                  {board?.files && board.files?.length > 0 && (
                    <>
                      {board?.files
                        .filter((file) => file.aiIsValidResume === true || file.isProcessed === false)
                        .map((file) => {
                          return (
                            <ProcessedFile
                              key={file.uuid}
                              file={file}
                              handleCreateClick={() => handleResumeCreateFromFileClick(file.uuid)}
                            />
                          );
                        })}
                    </>
                  )}
                  {board?.resumes && board.resumes?.length > 0 && (
                    <>
                      {board?.resumes.map((resume) => {
                        return (
                          <ProcessedResume
                            key={resume.uuid}
                            resume={resume}
                            handleEditClick={() => handleResumeEditClick(resume.uuid)}
                            handleDeleteClick={() => handleResumeDeleteClick(resume.uuid)}
                            handleCopyClick={() => handleResumeCopyClick(resume.uuid)}
                            handleDownloadClick={() => handleResumeDownload(resume.uuid)}
                          />
                        );
                      })}
                    </>
                  )}
                  <ProcessedResumeCreate
                    handleResumeCreateFromResume={handleResumeCreateFromResume}
                    handleResumeCreateFromScratch={handleResumeCreateFromScratch}
                  />
                </ProcessedFiles>
              </ErrorBoundary>
            </Suspense>
          </TabPanel>
        </TabController>
      </ErrorBoundary>
    );
  }

  return (
    <ViewWrapper>
      <Breadcrumbs>
        <Breadcrumb>
          <Link to={`/${Urls.Boards}`}>All Boards</Link>
        </Breadcrumb>
        <Breadcrumb>Board: {board?.title}</Breadcrumb>
      </Breadcrumbs>
      {boardComponent}
      {jobsComponent}
      {/* <pre>{JSON.stringify(board?.permissions, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(board, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(jobs, null, 2)}</pre> */}
      <ErrorBoundary message="'Board\View\Modals">
        <Modal isVisible={isModalVisible} setIsVisible={setIsModalVisible}>
          {modalVisibleType === 'add_event' && (
            <AddEventModal handleCancel={handleCancel} handleSubmit={handleJobAddEventSubmit} />
          )}
          {modalVisibleType === 'add_event_from_calendar' && (
            <AddEventFromCalendarModal
              currentDate={currentDate}
              jobs={jobs}
              handleCancel={handleCancel}
              handleSubmit={handleJobAddEventSubmit}
            />
          )}
          {modalVisibleType === 'add_contact' && (
            <AddContactModal handleCancel={handleCancel} handleSubmit={handleJobAddContactSubmit} />
          )}
          {modalVisibleType === 'invite_job' && (
            <InviteUserOnJobModal handleCancel={handleCancel} handleSubmit={handleJobInvite} />
          )}
          {modalVisibleType === 'invite_board' && (
            <InviteUserOnBoardModal handleCancel={handleCancel} handleSubmit={handleBoardInvite} />
          )}
          {modalVisibleType === 'confirm_remove_follower' && (
            <ConfirmDeleteModal title="Cancel" handleCancel={handleCancel} handleSubmit={handleFollowerDelete}>
              <>
                <b>Cancel the collaboration.</b>
                <br /> The collaborator will not be able to create new job opportunities or
                <br /> update the one he/she/them has created on this jobs board.
              </>
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'import_job' && (
            <ImportJobModal
              handleCancel={handleCancel}
              handleJobUrlSubmit={handleJobUrlSubmit}
              handleJobContentSubmit={handleJobContentSubmit}
              handleJobImportedClick={handleJobImportedClick}
              jobUuid={jobUuid}
            />
          )}
          {modalVisibleType === 'close_job' && (
            <CloseJobModal handleCancel={handleCancelJobClose} handleSubmit={handleCloseJobSubmit}></CloseJobModal>
          )}
          {modalVisibleType === 'delete_job' && (
            <ConfirmDeleteModal
              title="Delete Job"
              handleCancel={handleCancel}
              handleSubmit={() => handleJobDeleteConfirm()}
            >
              <Copy marginBottom={16}>Delete the Job Opportunity.</Copy>
              <Copy styleLevel={2} marginBottom={16}>
                The selected job listing will be <b>removed</b> from this Jobs Board.
              </Copy>
              <Copy styleLevel={2} marginBottom={16}>
                Please confirm.
              </Copy>
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'delete_resume' && (
            <ConfirmDeleteModal
              title="Delete Resume"
              handleCancel={handleCancel}
              handleSubmit={() => handleResumeDeleteConfirm()}
            >
              <Copy marginBottom={16}>Delete the Resume.</Copy>
              <Copy styleLevel={2} marginBottom={16}>
                The selected resume will be <b>removed</b> from this Jobs Board.
              </Copy>
              <Copy styleLevel={2} marginBottom={16}>
                Please confirm.
              </Copy>
            </ConfirmDeleteModal>
          )}
        </Modal>
      </ErrorBoundary>
    </ViewWrapper>
  );
};

const Undo = ({ children, onUndo, closeToast }: any) => {
  const handleClick = () => {
    onUndo();
    closeToast();
  };

  return (
    <div>
      {children}
      <button onClick={handleClick}>UNDO</button>
    </div>
  );
};
