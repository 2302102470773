import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationPublishBoardArgs } from '../../generated/types';

export const PUBLISH_BOARD = gql`
  mutation PublishBoard($userUuid: String!, $boardUuid: String!, $isPublic: Boolean!) {
    publishBoard(userUuid: $userUuid, boardUuid: $boardUuid, isPublic: $isPublic) {
      uuid
      isPublic
    }
  }
`;

export const usePublishBoard = (
  options?: MutationHookOptions<Pick<Mutation, 'publishBoard'>, MutationPublishBoardArgs>
) =>
  useMutation<Pick<Mutation, 'publishBoard'>, MutationPublishBoardArgs>(PUBLISH_BOARD, {
    ...options,
  });
