import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateJobFromContentArgs } from '../../generated/types';

export const CREATE_JOB_FROM_CONTENT = gql`
  mutation CreateJobFromContent($userUuid: String!, $boardUuid: String!, $data: JobFromContentInputData!) {
    createJobFromContent(userUuid: $userUuid, boardUuid: $boardUuid, data: $data)
  }
`;

export const useCreateJobFromContent = (
  options?: MutationHookOptions<Pick<Mutation, 'createJobFromContent'>, MutationCreateJobFromContentArgs>
) =>
  useMutation<Pick<Mutation, 'createJobFromContent'>, MutationCreateJobFromContentArgs>(CREATE_JOB_FROM_CONTENT, {
    ...options,
  });
