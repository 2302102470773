import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateResumeFromFileArgs } from '../../generated/types';

export const CREATE_RESUME_FROM_FILE = gql`
  mutation CreateResumeFromFile($userUuid: String!, $boardUuid: String!, $fileUuid: String!) {
    createResumeFromFile(userUuid: $userUuid, boardUuid: $boardUuid, fileUuid: $fileUuid) {
      uuid
    }
  }
`;

export const useCreateResumeFromFile = (
  options?: MutationHookOptions<Pick<Mutation, 'createResumeFromFile'>, MutationCreateResumeFromFileArgs>
) =>
  useMutation<Pick<Mutation, 'createResumeFromFile'>, MutationCreateResumeFromFileArgs>(CREATE_RESUME_FROM_FILE, {
    ...options,
  });
