import styled from 'styled-components';
import { Link as LinkReactRouter } from 'react-router-dom';

import { formatDate } from '../../../utils/date';

import { BoardDescriptionProps } from './BoardDescription.types';
import {
  ExternalLinkIcon,
  InfoIcon,
  LawIcon,
  LocationIcon,
  SchoolIcon,
  StarIcon,
  SuitcaseFullIcon,
} from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';
import { Colors } from '../../../styles/colors';
import { Sections, Section } from '../../atoms/Section';
import { media } from '../../../styles/media';
import { convertEnumToString } from '../../../utils/enum';
import { File } from '../../atoms/File';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';
import { Tooltip } from '../../atoms/Tooltip';
import { Copy } from '../../atoms/Typography';
import { IconButton } from '../../atoms/Icons/Icon';
import { PillsList } from '../../atoms/Pill/Pill';
import { Switch } from '../../atoms/Switch';
import { SharedLink } from '../../molecules/SharedLink';
import { Spacer } from '../../atoms/Spacer/Spacer';
import { Permissions } from '../../molecules/Permissions';
import { SubmitLink } from '../../molecules/SubmitLink';
import { InfoAlert } from '../../atoms/InfoAlert';
import { Link } from '../../atoms/Button';

export const BoardDescription: React.FC<any> = ({
  user,
  handleJobUrlSubmit,
  title,
  description,
  availableDate,
  linkedin,
  location,
  locationUrl,
  educationLevel,
  seniorityLevel,
  industry,
  interestLevel,
  coreSkills,
  workRightEU,
  workRightUK,
  isPublic,
  publicUrl,
  createdAt,
  updatedAt,
  isOwner,
  files,
  followers,
  onFollowerDeleteClick,
  onPublishClick,
}: React.PropsWithChildren<BoardDescriptionProps>) => {
  return (
    <ErrorBoundary message="Organism\BoardDescription Root Component">
      {/* <pre>{JSON.stringify(files, null, 2)}</pre> */}
      <Container>
        <HRSection />
        <Intro>
          <IntroMain>
            <JobTitleAndPills>
              <CompanyName>
                {title}
                {linkedin && (
                  <LinkReactRouter target="_blank" to={linkedin}>
                    <ExternalLinkIcon />
                  </LinkReactRouter>
                )}
              </CompanyName>
              <PillsList>
                {workRightEU && (
                  <Pill icon={<LawIcon />} isSmall={true} color={Colors.PrimaryDarkest}>
                    Working Right EU
                  </Pill>
                )}
                {workRightUK && (
                  <Pill icon={<LawIcon />} isSmall={true} color={Colors.PrimaryDarkest}>
                    Working Right UK
                  </Pill>
                )}
              </PillsList>
            </JobTitleAndPills>
            <Employment>
              {interestLevel && (
                <EmploymentContent>
                  <SuitcaseFullIcon size={1.5} />
                  {convertEnumToString('InterestLevel', interestLevel)}
                </EmploymentContent>
              )}
              {seniorityLevel && (
                <EmploymentContent>
                  <StarIcon size={1.5} />
                  {convertEnumToString('SeniorityLevel', seniorityLevel)}
                </EmploymentContent>
              )}
              {educationLevel && (
                <EmploymentContent>
                  <SchoolIcon size={1.5} />
                  {convertEnumToString('EducationLevel', educationLevel)}
                </EmploymentContent>
              )}
            </Employment>
            <Location>
              {location && (
                <>
                  <LocationIcon size={1.5} color={Colors.Contrast} />
                  {location}
                  {locationUrl && (
                    <LocationUrl target="_blank" to={locationUrl}>
                      <ExternalLinkIcon size={1} />
                    </LocationUrl>
                  )}
                </>
              )}
            </Location>
          </IntroMain>
          <IntroRight>
            {files?.length > 0 && (
              <Section>
                <SectionTitle>Attached Files</SectionTitle>
                {files.map((file) => {
                  return (
                    <SectionValue key={file.uuid}>
                      <File
                        onDownloadClick={() => {
                          if (!file.temporaryUrl) return;
                          window.open(file.temporaryUrl, '_blank');
                        }}
                        uuid={file.uuid}
                        filename={file.filename}
                        mimeType={file.mimetype}
                        formattedMimetype={file.formattedMimetype}
                      />
                    </SectionValue>
                  );
                })}
              </Section>
            )}
          </IntroRight>
        </Intro>
        <HRSection />
        <Sections>
          <Section>
            <SectionTitle>Available</SectionTitle>
            {availableDate ? (
              <SectionValue>
                {formatDate({
                  date: availableDate,
                  format: 'long',
                })}
              </SectionValue>
            ) : (
              <SectionValue>Unknown</SectionValue>
            )}
          </Section>
          <Section>
            <SectionTitle>Skills</SectionTitle>
            {coreSkills?.length > 0 ? (
              <PillsList>
                {coreSkills.map((coreSkill, i) => {
                  return (
                    <Pill key={i} isSmall={true} color={Colors.Primary}>
                      {coreSkill}
                    </Pill>
                  );
                })}
              </PillsList>
            ) : (
              <SectionValue>Not yet defined</SectionValue>
            )}
          </Section>
        </Sections>
        <Sections>
          <Section>
            <SectionTitle>Industry</SectionTitle>
            <SectionValue>{convertEnumToString('Industry', industry)}</SectionValue>
          </Section>
          <Section>
            <SectionTitle>History</SectionTitle>
            <SectionValue>
              <Copy styleLevel={3}>
                Created on{' '}
                {formatDate({
                  date: createdAt,
                  format: 'short',
                })}
              </Copy>
            </SectionValue>
            {updatedAt && (
              <SectionValue>
                <Copy styleLevel={3}>
                  Updated on{' '}
                  {formatDate({
                    date: updatedAt,
                    format: 'short',
                  })}
                </Copy>
              </SectionValue>
            )}
          </Section>
        </Sections>
        <Sections>
          <Section>
            <SectionTitle>
              Followers / Permissions
              <SectionTitleInfo>
                <Tooltip
                  title={
                    <IconButton>
                      <InfoIcon size={0.6} />
                    </IconButton>
                  }
                >
                  <Copy styleLevel={2}>
                    Followers have the flexibility to manage job opportunities seamlessly. They can easily add and
                    update job postings, tailoring them to specific needs. Moreover, they can conveniently download
                    required documents.
                  </Copy>
                </Tooltip>
              </SectionTitleInfo>
            </SectionTitle>
            {followers?.length > 0 ? (
              <Permissions followers={followers} onDeleteClick={onFollowerDeleteClick} />
            ) : (
              <SectionValue>
                You currently do not have any followers. To expand your network, consider inviting recruiters or agents
                to collaborate on your jobs boards. By doing so, they can access your latest documents and assist in
                creating and managing new job opportunities.
              </SectionValue>
            )}
          </Section>
          <Section>
            {isOwner && (
              <>
                <SharedLink url={publicUrl} isPublic={isPublic} />
                <Spacer y={16} />
                <Copy styleLevel={2} marginBottom={8}>
                  Activate this option to make your Jobs Board visible to both agents and the wider public. Once
                  activated, simply copy the link and share it with anyone you wish.
                </Copy>
                <Copy styleLevel={3} marginBottom={8}>
                  You have the flexibility to disable it whenever you prefer.
                </Copy>
                <Copy styleLevel={3} marginBottom={8}>
                  Note that only your jobs board and resume will be visible.
                </Copy>
                <Copy styleLevel={3} marginBottom={8}>
                  <strong>Your job listing will remain private.</strong>.
                </Copy>
                <SharedLineWrapper>
                  Enable Shared Link
                  <Switch isOn={!!isPublic} onChange={() => onPublishClick(!isPublic)} />
                </SharedLineWrapper>
              </>
            )}
          </Section>
        </Sections>
        {description && (
          <Sections>
            <Section>
              <SectionTitle>Description</SectionTitle>
              <SectionDescription>{description}</SectionDescription>
            </Section>
          </Sections>
        )}
      </Container>
    </ErrorBoundary>
  );
};

const Container = styled.div``;

const Intro = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const IntroMain = styled.div`
  flex: 4;
`;

const IntroRight = styled.div`
  flex: 1.5;
`;

const CompanyName = styled.div`
  display: flex;
  font-size: 2rem;
`;

const Location = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 1.1rem;
`;

const Employment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 8px;

  ${media.from_laptop`
    justify-content: flex-start;
    margin-bottom: 16px;
  `}
`;

const EmploymentContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const JobTitleAndPills = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px;

  ${media.from_laptop`
    justify-content: flex-start;
    gap: 16px;
  `}
`;

const SectionTitle = styled.div`
  color: ${Colors.GreyLightest};
  font-size: 1.2rem;
  line-height: 2rem;
  display: flex;
`;

const SectionTitleInfo = styled.div`
  transform: translateY(-6px);
`;

const SectionValue = styled.div`
  display: flex;
  gap: 4px;
  color: ${Colors.GreyDarkest};
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 8px;
`;

const SectionDescription = styled.div`
  color: ${Colors.Black};
  font-size: 1rem;
  line-height: 2rem;
`;

const HRSection = styled.hr`
  /* border: 0;
  height: 1px;
  background: ${Colors.Contrast};
  background-image: linear-gradient(to right, #ccc, ${Colors.Contrast}, #ccc); */

  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    hsl(300, 15%, 70%, 1),
    hsl(300, 15%, 70%, 1),
    hsl(300, 15%, 70%, 1),
    hsl(300, 15%, 70%, 1),
    rgba(0, 0, 0, 0)
  );

  margin: 8px 0 16px 0;

  ${media.from_tablet`
    margin: 8px 0 32px 0;
  `}
`;

const LocationUrl = styled(LinkReactRouter)`
  transform: translateY(-8px);
`;

const SharedLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;
  font-size: 1.3rem;
  font-weight: 600;
`;
