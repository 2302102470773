import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCopyResumeArgs } from '../../generated/types';

export const COPY_RESUME = gql`
  mutation CopyResume($userUuid: String!, $boardUuid: String!, $resumeUuid: String!) {
    copyResume(userUuid: $userUuid, boardUuid: $boardUuid, resumeUuid: $resumeUuid) {
      uuid
    }
  }
`;

export const useCopyResume = (options?: MutationHookOptions<Pick<Mutation, 'copyResume'>, MutationCopyResumeArgs>) =>
  useMutation<Pick<Mutation, 'copyResume'>, MutationCopyResumeArgs>(COPY_RESUME, {
    ...options,
  });
