import { ToastProps } from './Toast.types';
import { Container } from './Toast.styles';
import { Button, PrimaryButton } from '../Button/Buttons';
import { UndoIcon } from '../Icons';
import { Colors } from '../../../styles/colors';

export const Toast = ({ children, onUndo }: React.PropsWithChildren<ToastProps>) => {
  const handleClick = () => {
    if (onUndo) {
      onUndo();
    }
  };

  return (
    <Container>
      {children}
      {Boolean(onUndo) && (
        <PrimaryButton iconRight={<UndoIcon color={Colors.White} />} size="tiny" onClick={handleClick} inline={true}>
          Undo
        </PrimaryButton>
      )}
    </Container>
  );
};
