import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { ToastProps } from './Toast.types';

export const Container = styled.div<ToastProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.from_tablet`
    padding: 10px 10px 5px;
  `}
`;
